import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';

const mock = [
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img8.png',
    title: 'Out-of-pocket medical expenses',
    subtitle:
      'Without health insurance, you may be responsible for paying the full cost of medical services, including doctor visits, hospital stays, diagnostic tests and prescription drugs. These costs can be substantial and can add up quickly, especially for major medical expenses.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Delayedcare.png',
    title: 'Delayed care',
    subtitle:
      'Without health insurance, you may be more likely to delay seeking medical care, which can lead to more serious health problems and increased medical expenses in the long run.',
  },
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img1.jpg',
    title: 'Reduced access to care',
    subtitle:
      'Without health insurance, you may have limited access to medical care and treatments.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Reducedaccesstohealthcare.png',
    title: 'Financial hardship',
    subtitle:
      'Medical expenses can lead to significant debt, especially for those who are uninsured. This debt can be difficult to pay off and can have a negative impact on your financial stability.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
    title: 'Lack of preventive care',
    subtitle:
      'Without health insurance, you may not have access to preventive care such as regular check-ups, screenings, and vaccinations, which can lead to more serious health problems in the future.',
  },
];

const News = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        {/* <Typography
          variant={'h4'}
          gutterBottom
          align={'center'}
          sx={{ fontWeight: 700 }}
        >
          Our latest news
        </Typography> */}
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          However, in general, the cost of not having health insurance can be
          substantial and can include but not limited to the following:
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            {mock.map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 200}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Card}
                  display={'flex'}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      height: { xs: 240, sm: 'auto' },
                      width: { xs: 1, sm: 300 },
                    }}
                  />
                  <CardContent>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    You like what you’re reading?
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ color: 'common.white' }}
                  >
                    Get online insurance advice.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent>
                  <Typography variant="h6" gutterBottom color="text.primary">
                    Interactive decision support system
                  </Typography>
                  <Link
                    to={'/contact'}
                    style={{ textDecoration: 'none' }} // Remove default link styling
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={
                        <Box
                          component={'svg'}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </Box>
                      }
                    >
                      Contact us
                    </Button>
                  </Link>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box marginBottom={4} marginTop={2}>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          In conclusion, the cost of not having health insurance can be
          substantial and can result in financial hardship for you and your
          family. Having health insurance can provide peace of mind and help
          manage the costs associated with medical expenses, ensuring that you
          and your family have access to the care you need when you need it.
        </Typography>
      </Box>
    </Box>
  );
};

export default News;
