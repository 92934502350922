/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img3.jpg',
    description:
      "The availability and cost of health insurance can also vary depending on a number of factors, including the policyholder's age, health status and level of coverage.",
    desc:"It is recommended to carefully review the options available and consult with a financial adviser to determine the best health insurance policy that satisfies your needs and budget.",    
    title: 'Health Insurance',
    tags: ['doctor visits', 'hospital stays', 'diagnostic tests', 'surgery'],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img4.jpg',
    description:
      'The cost of not having health insurance can vary depending on a number of factors, such as the severity of your heath condition and how long you will have to wait for the public health system.',
      desc:"Without health insurance, you may be responsible for paying the full cost of your medical expenses out of pocket. This can result in large, unexpected bills and financial burdens, especially in the case of serious illness or injury.",
      title: 'The Cost of Not Having Health Insurance',
      tags: ['doctor visits', 'hospital stays', 'diagnostic tests', 'surgery'],
      author: {
        name: 'Jhon Anderson',
        avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
      },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Health Insurance
        </Typography>
        <Typography
          variant="h6"
          color={'text.secondary'}
          align={'center'}
          data-aos={'fade-up'}
        >
          Health insurance is a type of insurance that provides coverage for medical expenses. 
          <br />
          It typically covers expenses such as doctor visits, specialist visits, hospital stays, diagnostic tests, surgery and some overseas treatments.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={'flex'}
              flexDirection={{
                xs: 'column',
                md: i % 2 === 0 ? 'row-reverse' : 'row',
              }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 360,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  paddingY: { xs: 2, sm: 4 },
                  width: { xs: 1, md: '50%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {item.tags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      component="a"
                      href=""
                      clickable
                      size={'small'}
                      color={'primary'}
                      sx={{ marginBottom: 1, marginRight: 1 }}
                    />
                  ))}
                </Box>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {item.title}
                </Typography>
                {/* <Box marginY={1}>
                  <Typography
                    variant={'caption'}
                    color={'text.secondary'}
                    component={'i'}
                  >
                    {item.author.name} - {item.date}
                  </Typography>
                </Box> */}
                <Typography color="text.secondary">
                  {item.description}
                  <br/>
                  <br/>
                  {item.desc}
                </Typography>
                {/* <Box marginTop={2} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    Read More
                  </Button>
                </Box> */}
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularNews;
